import React from "react";

import clsx from "clsx";

function CrossCircleIcon(props: React.ComponentPropsWithoutRef<"svg"> & { className?: string }) {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={clsx("stroke-examedi-gray-strong", props.className)}
    >
      <path
        d="M7.89964 22.0418C11.8049 25.9471 18.1365 25.9471 22.0418 22.0418C25.947 18.1366 25.947 11.8049 22.0418 7.89968C18.1365 3.99444 11.8049 3.99444 7.89964 7.89968C3.99439 11.8049 3.99439 18.1366 7.89964 22.0418Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.7988 12.1421L12.142 17.7989"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1426 12.1421L17.7994 17.7989"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CrossCircleIcon;
