import React from "react";

import clsx from "clsx";

function PlusIcon(props: React.ComponentPropsWithoutRef<"svg"> & { className?: string }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={clsx("fill-g1", props.className)}
    >
      <path d="M20.9998 11.9996C20.9998 12.3803 20.6882 12.6919 20.3074 12.6919H12.6921V20.3073C12.6921 20.6899 12.3824 21 11.9998 21C11.6171 21 11.3074 20.688 11.3074 20.3073V12.6919H3.69206C3.30939 12.6919 2.99976 12.3825 2.99976 12C2.99976 11.6188 3.30956 11.3073 3.69206 11.3073H11.3074V3.69188C11.3074 3.3092 11.6171 3 11.9998 3C12.3824 3 12.6921 3.30938 12.6921 3.69188V11.3073H20.3074C20.6882 11.3073 20.9998 11.6188 20.9998 11.9996Z" />
    </svg>
  );
}

export default PlusIcon;
