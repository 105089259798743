import React, { useState } from "react";

import clsx from "clsx";

type TabsProps = {
  elements: string[];
  defaultValue?: string;
  contentClassName?: string;
  children?: React.ReactNode;
  parser?: (value: string) => string;
  onSelectTab?: (value: string) => void;
  onTabChange?: () => void;
};

const Tabs = ({ elements, defaultValue, contentClassName, children, parser, onSelectTab, onTabChange }: TabsProps) => {
  const [selected, setSelected] = useState<string>(defaultValue || elements[0]);

  return (
    <div className={clsx("w-full", "flex flex-col items-start justify-center", "gap-y-0")}>
      <div className={clsx("w-full", "flex justify-start")}>
        {elements.map((element) => (
          <div
            key={element}
            className={clsx(
              "min-w-[10rem]",
              "px-3 py-2",
              "text-center",
              "border-b border-examedi-gray-4",
              "cursor-pointer",
              selected === element && ["text-examedi-blue-strong", "border-b-2 border-b-examedi-blue-strong"],
            )}
            onClick={() => {
              setSelected(element);
              onSelectTab?.(element);
              onTabChange?.();
            }}
          >
            {parser?.(element) || element}
          </div>
        ))}
        <div className="w-full py-2 border-b border-examedi-gray-4">&nbsp;</div>
      </div>
      <div className={clsx("w-full py-4", contentClassName)}>{children}</div>
    </div>
  );
};

export default Tabs;
