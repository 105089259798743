import React from "react";

import clsx from "clsx";

type InputDateProps = {
  id: string;
  name: string;
  placeholder: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  value: string;
  className?: string | null | false;
  disabled?: boolean;
};

function InputDate({ id, disabled, className, name, placeholder, onChange, onBlur, value }: InputDateProps) {
  return (
    <input
      type="date"
      className={clsx(
        "w-full",
        "py-[0.7rem] px-[0.8rem]",
        "text-sm",
        "bg-transparent",
        "rounded-lg border border-examedi-gray-5",
        "placeholder:text-examedi-gray-regular",
        "placeholder:text-sm",
        "focus:ring-0",
        "focus:outline-none",
        "focus:shadow-none",
        "focus:!border-examedi-blue-strong",
        "focus:text-examedi-gray-2",
        "bg-examedi-white-lighter",
        "disabled:cursor-not-allowed",
        "disabled:text-examedi-gray-3",
        "disabled:bg-examedi-gray-6",
        "transition-colors duration-150",
        className,
      )}
      id={id}
      name={name}
      disabled={disabled}
      placeholder={placeholder}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
    />
  );
}

export default React.memo(InputDate);
