import { AxiosResponse } from "axios";
import qs from "qs";

import api from "~/utils/api/api";
import { BlockedNurseItemDetailed } from "~/utils/interfaces/Items";
import { Laboratory } from "~/utils/interfaces/Laboratory";
import { Nurse, NurseActionReceiver, NurseSpecialty } from "~/utils/interfaces/Nurse";
import { RequestError, RequestResponse } from "~/utils/interfaces/request";

class NurseAPI {
  listMedicalServices = async (nurseId: string): Promise<AxiosResponse<any>> => {
    return api.get(`v2/nurses/${nurseId}/medical-services/`);
  };
  listMedicalActions = async (nurseId: string): Promise<AxiosResponse<any>> => {
    return api.get(`v2/nurses/${nurseId}/medical-actions/`);
  };
  deleteMedicalActions = async (nurseId: string, medicalActionIds: string[]): Promise<AxiosResponse<any>> => {
    return api.delete(`v2/nurses/${nurseId}/medical-actions/`, {
      data: medicalActionIds,
    });
  };

  deleteMedicalServices = async (nurseId: string, medicalServiceIds: string[]): Promise<AxiosResponse<any>> => {
    return api.delete(`v2/nurses/${nurseId}/medical-services/`, {
      data: medicalServiceIds,
    });
  };

  addMedicalActions = async (nurseId: string, medicalActionIds: string[]): Promise<AxiosResponse<any>> => {
    return api.post(`v2/nurses/${nurseId}/medical-actions/`, medicalActionIds);
  };

  addMedicalServices = async (nurseId: string, medicalServiceIds: string[]): Promise<AxiosResponse<any>> => {
    return api.post(`v2/nurses/${nurseId}/medical-services/`, medicalServiceIds);
  };

  listActionReceivers = async (nurseId: string): Promise<AxiosResponse<any>> => {
    return api.get(`v2/nurses/${nurseId}/action-receivers/`);
  };

  addActionReceiver = async ({ nurseId, receiverId, medicalActionId }): Promise<AxiosResponse<any>> => {
    return api.post(`v2/nurses/${nurseId}/action-receivers/`, {
      receiver_id: receiverId,
      medical_action_id: medicalActionId,
    });
  };

  deleteActionReceiver = async ({ nurseId, receiverId, medicalActionId }): Promise<AxiosResponse<any>> => {
    return api.delete(`v2/nurses/${nurseId}/action-receivers/`, {
      data: {
        receiver_id: receiverId,
        medical_action_id: medicalActionId,
      },
    });
  };

  fetchNurseLabs = async (nurseId: string): Promise<Laboratory[] | undefined> => {
    try {
      const res = await api.get<Laboratory[]>(`v2/nurses/${nurseId}/labs/`);
      return res.data;
    } catch (error) {
      return undefined;
    }
  };

  createManyNurseActionReceivers = async (
    nurseId: string,
    receiverIds: string[],
    medicalActionIds: string[],
  ): Promise<NurseActionReceiver[] | undefined> => {
    try {
      const res = await api.post<NurseActionReceiver[]>("v2/nurse-action-receivers/create-many/", {
        nurse_id: nurseId,
        receiver_ids: receiverIds,
        medical_action_ids: medicalActionIds,
      });
      return res.data;
    } catch (error) {
      return undefined;
    }
  };

  checkAvailable = async (appointmentId: string, formattedDate: string, nurseId?: string) => {
    try {
      const res = await api.get(
        `v2/nurses/check-available?date=${formattedDate}&appointment_id=${appointmentId}${
          nurseId ? `&nurse_id=${nurseId}` : ""
        }`,
      );
      return res.data;
    } catch (e) {
      return undefined;
    }
  };

  checkServices = async (id: string, appointmentId: string) => {
    try {
      const res = await api.get(`/v2/nurses/${id}/check-services?appointment_id=${appointmentId}`);
      return res.data;
    } catch (err) {
      return undefined;
    }
  };
}

export async function listNurseSpecialties(): Promise<NurseSpecialty[] | undefined> {
  try {
    const res = await api.get<NurseSpecialty[]>(`v2/specialties/`);
    return res.data;
  } catch (error) {
    return undefined;
  }
}

export async function listNursesPerCountry({
  countryCode,
  active,
  nurseIds,
}: {
  countryCode: string;
  active?: boolean;
  nurseIds?: string[];
}): Promise<Nurse[] | { error: string }> {
  try {
    const res = await api.get<Nurse[]>("v2/nurses/", {
      params: { id__in: nurseIds, country: countryCode, active },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "comma" });
      },
    });
    return res.data;
  } catch (error) {
    return { error: JSON.stringify(error) };
  }
}

export async function getBlockedItems(nurseId: string): Promise<RequestResponse<BlockedNurseItemDetailed[]>> {
  try {
    const res = await api.get<BlockedNurseItemDetailed[]>(`v2/nurses/${nurseId}/blocked-items/`);
    return { data: res.data };
  } catch (error) {
    return { error } as RequestError;
  }
}

const nurseAPI = new NurseAPI();
export default nurseAPI;
