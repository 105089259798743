import React, { useEffect, useRef, useState } from "react";

import ReactDOM from "react-dom";

type DrawerViewDirection = "top" | "bottom" | "left" | "right";

type DrawerViewConfig = {
  direction: DrawerViewDirection;
  hideBackdrop?: boolean;
  className?: string;
  onBeforeClose?: () => Promise<boolean>;
  onClose?: () => void;
};

type DrawerViewDirectionConfig = {
  visibleStyleTransform: string;
  hiddenStyleTransform: string;
};

const DEFAULT_DIRECTION: DrawerViewDirection = "left";

const INIT_CONFIG: Record<DrawerViewDirection, DrawerViewDirectionConfig> = {
  top: {
    visibleStyleTransform: "translateY(0)",
    hiddenStyleTransform: "translateY(-100%)",
  },
  bottom: {
    visibleStyleTransform: "translateY(0)",
    hiddenStyleTransform: "translateY(100%)",
  },
  left: {
    visibleStyleTransform: "translateX(0%)",
    hiddenStyleTransform: "translateX(-100%)",
  },
  right: {
    visibleStyleTransform: "translateX(0)",
    hiddenStyleTransform: "translateX(100%)",
  },
};

type DrawerViewCloseConfig = {
  forced?: boolean;
};

function useDrawerView(initConfig?: Partial<DrawerViewConfig>) {
  const [content, setContent] = useState<React.ReactElement | null>(null);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const backdropRef = useRef<HTMLDivElement | null>(null);
  const configRef = useRef<DrawerViewConfig | null>(null);
  const isOpenRef = useRef<boolean>(false);

  function validateConfig(config?: Partial<DrawerViewConfig>): DrawerViewConfig {
    const direction = config?.direction || DEFAULT_DIRECTION;
    const hideBackdrop = config?.hideBackdrop;
    const className = config?.className;
    return {
      direction,
      hideBackdrop,
      className,
    };
  }

  function initializeDOMElement(config: DrawerViewConfig) {
    const { direction } = config;
    const { hiddenStyleTransform } = INIT_CONFIG[direction];
    isOpenRef.current = false;
    contentRef.current!.setAttribute("class", "absolute left-0 top-0 z-10 h-full " + config.className);
    contentRef.current!.style.transition = "none";
    contentRef.current!.style.transform = hiddenStyleTransform;
    containerRef.current!.style.zIndex = "-10";
  }

  function isOpen() {
    return isOpenRef.current;
  }

  function open(content: React.ReactElement, config_?: Partial<DrawerViewConfig>, replaceOriginalConfig?: boolean) {
    const validatedConfig = validateConfig(config_);
    if (replaceOriginalConfig) {
      configRef.current = validatedConfig;
    }
    const { direction, hideBackdrop } = config_ ? validateConfig(config_) : configRef.current!;
    document.body.style.overflowY = "hidden";
    setContent(content);
    isOpenRef.current = true;
    const { visibleStyleTransform, hiddenStyleTransform } = INIT_CONFIG[direction];
    containerRef.current!.style.zIndex = "60";
    contentRef.current!.style.transform = hiddenStyleTransform;
    contentRef.current!.style.transition = "transform 400ms cubic-bezier(0.8, 0.03, 0.58, 1) 0ms";
    contentRef.current!.style.transform = visibleStyleTransform;
    if (!hideBackdrop) {
      backdropRef.current!.style.opacity = "1";
    }
  }

  async function confirmClose(): Promise<boolean> {
    if (initConfig?.onBeforeClose) {
      return initConfig.onBeforeClose();
    }
    return true;
  }

  async function close(props?: DrawerViewCloseConfig) {
    let result = true;
    if (!props?.forced) {
      result = await confirmClose();
    }
    if (result) {
      document.body.style.overflowY = "auto";
      const { direction } = configRef.current!;
      const { hiddenStyleTransform } = INIT_CONFIG[direction];
      contentRef.current!.style.transform = hiddenStyleTransform;
      if (!configRef.current!.hideBackdrop) {
        backdropRef.current!.style.opacity = "0";
      }
      initConfig?.onClose?.();
      setTimeout(() => {
        initializeDOMElement(configRef.current!);
        setContent(null);
      }, 600);
    }
  }

  function clear() {
    containerRef.current?.remove();
  }

  function internalClose() {
    close();
  }

  function replaceContent(content: React.ReactElement) {
    setContent(content);
  }

  useEffect(() => {
    const backdropElement = document.createElement("div");
    if (!configRef.current) {
      const config = validateConfig(initConfig);
      configRef.current = config;
      const mainElement = document.getElementById("__next") || document.body;
      // fixed
      const containerFixedElement = document.createElement("div");
      containerFixedElement.setAttribute("class", "fixed -z-10 left-0 top-0 h-[100dvh] w-screen");
      containerRef.current = containerFixedElement;
      // fixed > relative
      const containerRelativeElement = document.createElement("div");
      containerRelativeElement.setAttribute("class", "relative h-full w-full");
      containerFixedElement.appendChild(containerRelativeElement);
      // fixed > relative > absolute
      const contentElement = document.createElement("div");
      containerRelativeElement.appendChild(contentElement);
      contentRef.current = contentElement;
      initializeDOMElement(config);
      if (!configRef.current.hideBackdrop) {
        // fixed > relative > backdrop
        backdropElement.addEventListener("click", internalClose);
        backdropElement.setAttribute(
          "class",
          "absolute left-0 opacity-0 transition-opacity duration-200 top-0 z-0 h-full w-full bg-black/60",
        );
        backdropRef.current = backdropElement;
        containerRelativeElement.appendChild(backdropElement);
      }
      mainElement.appendChild(containerFixedElement);
    }
    return () => {
      if (backdropElement) {
        backdropElement.removeEventListener("click", internalClose);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    elementRef: contentRef.current,
    content: content ? <>{ReactDOM.createPortal(content, contentRef.current || document.body)}</> : null,
    open,
    close,
    replaceContent,
    isOpen,
    clear,
  };
}

export default useDrawerView;
