import Auth from "~/utils/auth/auth";

const auth = new Auth();

const usePermissions = () => {
  const isFront = typeof window !== "undefined";
  const isSuperadmin = isFront ? auth.isSuperadmin() : false;
  const isSuperadmin1 = isFront ? auth.isSuperadmin1() : false;
  const isCouponAdmin = isFront ? auth.isCouponAdmin() : false;
  const isNursePaymentAdmin = isFront ? auth.isNursePaymentAdmin() : false;
  const isNursePaymentEdit = isFront ? auth.isNursePaymentEdit() : false;

  return {
    isSuperadmin,
    isSuperadmin1,
    isCouponAdmin,
    isNursePaymentAdmin,
    isNursePaymentEdit,
  };
};

export default usePermissions;
