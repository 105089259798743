import React, { useRef, useEffect } from "react";

import clsx from "clsx";

type InputNumberProps = {
  name: string;
  placeholder: string;
  value?: string | number;
  prefix?: string;
  suffix?: string;
  min?: number;
  max?: number;
  className?: string;
  disabled?: boolean;
  focusOnMount?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
};

function InputNumber({
  name,
  placeholder,
  value,
  suffix,
  min = 0,
  max,
  className,
  disabled,
  focusOnMount,
  onChange,
  onBlur,
}: InputNumberProps) {
  const inputElementRef = useRef<HTMLInputElement>(null);

  const handleSuffixFocus = () => {
    if (inputElementRef.current) {
      inputElementRef.current.focus();
    }
  };

  useEffect(() => {
    if (focusOnMount) {
      handleSuffixFocus();
    }
  }, [focusOnMount]);

  return (
    <div
      className={clsx(
        "h-fit w-full",
        "grid grid-cols-8",
        "rounded-lg border border-examedi-gray-5",
        "focus-within:!border-examedi-blue-strong",
        "focus-within:!text-examedi-gray-2",
        "transition-colors duration-150",
      )}
    >
      <input
        ref={inputElementRef}
        type="number"
        className={clsx(
          "w-full",
          "py-[0.7rem] px-[0.8rem]",
          "text-sm",
          "col-span-6",
          "bg-transparent",
          "placeholder:text-examedi-gray-regular",
          "placeholder:text-sm",
          "border-none",
          "focus:ring-0",
          "focus:outline-none",
          "focus:shadow-none",
          "focus:placeholder:text-transparent",
          "disabled:!cursor-not-allowed",
          "disabled:placeholder:text-examedi-gray-line",
          "disabled:!text-examedi-gray-line",
          "disabled:!bg-white",
          !suffix && "!col-span-full",
          className,
        )}
        name={name}
        placeholder={placeholder}
        value={value || ""}
        disabled={disabled}
        onChange={onChange}
        min={min}
        max={max}
        onBlur={(e) => onBlur?.(e)}
      />
      {!!suffix && (
        <div
          onClick={handleSuffixFocus}
          className={clsx(
            "h-full w-full",
            "pr-3",
            "col-span-2",
            "flex items-center justify-end",
            "bg-transparent",
            "text-examedi-gray-regular",
            "cursor-text",
          )}
        >
          {suffix}
        </div>
      )}
    </div>
  );
}

export default React.memo(InputNumber);
