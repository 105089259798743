import { Locale } from "~/utils/interfaces/Locale";

export const numberWithPeriods = (raw: number, countryCode: "cl" | "mx" = "cl"): string => {
  let separator = ".";
  if (raw == 0) {
    return "0";
  } else if (!raw) {
    return "N/A";
  }
  if (countryCode === "mx") {
    separator = ",";
  }
  return Math.floor(raw)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, separator);
};

export const onlyNumbers = (str: string) => {
  return str.replace(/\D/g, "");
};

export const truncateTwoDecimalPlaces = (num: number) => {
  return Math.round((num + Number.EPSILON) * 100) / 100;
};

export function currencyFormat(value: number, locale: Locale = "cl") {
  const localeISO = locale === "cl" ? "es-CL" : "es-MX";
  const currency = locale === "cl" ? "CLP" : "MXN";
  const formatter = new Intl.NumberFormat(localeISO, {
    currency: currency,
    style: "currency",
    currencyDisplay: "narrowSymbol",
  });
  return formatter.format(value);
}
