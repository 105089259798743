import React, { useEffect, useState } from "react";

import clsx from "clsx";
import Swal from "sweetalert2";

import LoadingSpinnerIcon from "~/components/icons/LoadingSpinnerIcon";
import TablePagination from "~/components/Tables/TablePagination";
import { listPaymentsPerDay } from "~/utils/api/v2/nursePayments";
import { Locale } from "~/utils/interfaces/Locale";
import { Nurse } from "~/utils/interfaces/Nurse";
import { NursePaymentDaySummary, NursePaymentDetailParams } from "~/utils/interfaces/nursePayments";
import { numberWithPeriods } from "~/utils/numbers";

type NursePaymentsTableProps = {
  selectedDate?: { month: number; year: number };
  selectedNurse?: Nurse;
  countryCode: Locale;
  openDrawer: () => void;
  setDrawerDetailParams: (params: NursePaymentDetailParams) => void;
  setTotalMonthAmount: (value: number) => void;
};

const pageLength = 20;

function NursePaymentsTable({
  selectedDate,
  selectedNurse,
  countryCode,
  openDrawer,
  setDrawerDetailParams,
  setTotalMonthAmount,
}: NursePaymentsTableProps) {
  const [page, setPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [nursePayments, setNursePayments] = useState<NursePaymentDaySummary[]>([]);
  const [numberOfPages, setNumberOfPages] = useState<number>(1);
  const currentPayments = nursePayments.slice(pageLength * (page - 1), pageLength * page);

  const getAndSetPayments = async () => {
    if (!selectedDate) {
      setNursePayments([]);
      return;
    }
    setLoading(true);
    const nursePaymentsResponse = await listPaymentsPerDay({
      country: countryCode,
      month: selectedDate?.month + 1,
      year: selectedDate?.year,
      nurseId: selectedNurse?.id,
    });
    if ("error" in nursePaymentsResponse) {
      const errorMessage = nursePaymentsResponse.error as string;
      Swal.fire({
        title: "Error",
        icon: "error",
        text: errorMessage,
      });
      return;
    }
    setNumberOfPages(Math.ceil(nursePaymentsResponse.length / pageLength));
    setNursePayments(nursePaymentsResponse);
    setTotalMonthAmount(nursePaymentsResponse.reduce((acc, curr) => acc + curr.total, 0));
    setLoading(false);
  };

  const handleSeeDetail = (nurseDayPayment: NursePaymentDaySummary) => {
    setDrawerDetailParams({
      nurseName: nurseDayPayment.nurse_name,
      date: nurseDayPayment.date,
      nurseId: nurseDayPayment.nurse_id,
    });
    openDrawer();
  };

  useEffect(() => {
    getAndSetPayments();
  }, [selectedDate, selectedNurse?.id, countryCode]);

  return (
    <>
      <table className="w-full h-full border">
        <thead>
          <tr className="bg-white border-b">
            <th></th>
            <th className="py-3">Fecha</th>
            <th className="py-3">Nombre</th>
            <th className="py-3">Nº de Appointments</th>
            <th className="py-3">Pago domicilio</th>
            <th className="py-3">Pago pacientes</th>
            <th className="py-3">Pago otros</th>
            <th className="py-3">Pago del día</th>
          </tr>
        </thead>
        <tbody>
          {!loading &&
            currentPayments.map((nurseDayPayment) => (
              <tr
                className={clsx("bg-white border-b", "cursor-pointer")}
                key={`${nurseDayPayment.nurse_id}-${nurseDayPayment.date}`}
                onClick={() => handleSeeDetail(nurseDayPayment)}
              >
                <td className={clsx("flex justify-center", "py-2", "font-semibold text-examedi-blue-strong")}>
                  Ver detalle
                </td>
                <td>{nurseDayPayment.date}</td>
                <td>{nurseDayPayment.nurse_name}</td>
                <td>{nurseDayPayment.n_appointments}</td>
                <td>{numberWithPeriods(nurseDayPayment.delivery_amount, countryCode)}</td>
                <td>{numberWithPeriods(nurseDayPayment.patients_amount, countryCode)}</td>
                <td>
                  {numberWithPeriods(
                    nurseDayPayment.total - nurseDayPayment.patients_amount - nurseDayPayment.delivery_amount,
                    countryCode,
                  )}
                </td>
                <td>{numberWithPeriods(nurseDayPayment.total, countryCode)}</td>
              </tr>
            ))}
          {!loading && currentPayments.length === 0 && (
            <tr className="bg-white">
              <td
                colSpan={9}
                className="text-center"
              >
                No hay pagos para mostrar
              </td>
            </tr>
          )}
          {loading && (
            <tr className="bg-white">
              <td colSpan={9}>
                <LoadingSpinnerIcon />
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <div className="flex w-full justify-end mt-3">
        <TablePagination
          pages={numberOfPages}
          currentPage={page}
          setPage={setPage}
        />
      </div>
    </>
  );
}

export default NursePaymentsTable;
