import React from "react";

import clsx from "clsx";

import LeftArrowIcon from "~/icons/LeftArrow";
import RightArrowIcon from "~/icons/RightArrow";

type TablePaginationProps = {
  pages: number;
  currentPage: number;
  setPage: (newPage: number) => void;
};

const TablePagination = (props: TablePaginationProps) => {
  const first = props.currentPage === 1;
  const last = props.currentPage === props.pages;

  return (
    <nav aria-label="Page navigation example">
      <ul className="inline-flex items-center -space-x-px">
        <li>
          <span
            className={clsx(
              "block px-3 py-2",
              "ml-0 leading-tight text-gray-500",
              "border border-gray-300",
              "rounded-l-lg",
              !first && "hover:bg-examedi-gray-6 hover:text-gray-700 hover:cursor-pointer",
              first && "cursor-not-allowed bg-examedi-gray-5",
            )}
            onClick={() => !first && props.setPage(props.currentPage - 1)}
          >
            <LeftArrowIcon />
          </span>
        </li>
        {!first && (
          <li>
            <span
              className={clsx(
                "px-3 py-2",
                "leading-tight border border-gray-300",
                "hover:bg-gray-100 hover:text-gray-700 hover:cursor-pointer",
              )}
              onClick={() => !first && props.setPage(props.currentPage - 1)}
            >
              {props.currentPage - 1}
            </span>
          </li>
        )}
        <li>
          <span
            className={clsx(
              "px-3 py-2",
              "text-examedi-blue-normal",
              "leading-tight border border-examedi-blue-ligher",
              "bg-examedi-blue-lightest",
            )}
          >
            {props.currentPage}
          </span>
        </li>
        {!last && (
          <li>
            <span
              className={clsx(
                "px-3 py-2",
                "leading-tight border border-gray-300",
                "hover:bg-gray-100 hover:text-gray-700 hover:cursor-pointer",
              )}
              onClick={() => !last && props.setPage(props.currentPage + 1)}
            >
              {props.currentPage + 1}
            </span>
          </li>
        )}
        <li>
          <span
            className={clsx(
              "block px-3 py-2",
              "leading-tight text-gray-500",
              "border border-gray-300",
              "rounded-r-lg",
              !last && "hover:bg-examedi-gray-6 hover:text-gray-700 hover:cursor-pointer",
              last && "cursor-not-allowed bg-examedi-gray-5",
            )}
            onClick={() => !last && props.setPage(props.currentPage + 1)}
          >
            <RightArrowIcon />
          </span>
        </li>
      </ul>
    </nav>
  );
};

export default TablePagination;
