import React, { useEffect, useState } from "react";

import { navigate } from "gatsby";
import Swal from "sweetalert2";

import PrivateRoute from "~/components/Authentication/PrivateRoute";
import CreateNursePaymentButton from "~/components/nursePayments/CreateNursePaymentButton";
import CreateNursePaymentModal from "~/components/nursePayments/CreateNursePaymentModal";
import NursePaymentDetailDrawer from "~/components/nursePayments/NursePaymentDetailDrawer";
import NursePaymentsTable from "~/components/nursePayments/NursePaymentsTable";
import PaymentFilters from "~/components/nursePayments/PaymentFilters";
import usePermissions from "~/hooks/usePermissions";
import { getNurseMonthSummary } from "~/utils/api/v2/nursePayments";
import { monthNames } from "~/utils/dates/names";
import { Locale } from "~/utils/interfaces/Locale";
import { Nurse } from "~/utils/interfaces/Nurse";
import { NurseMonthSummary, NursePaymentDetailParams } from "~/utils/interfaces/nursePayments";
import { numberWithPeriods } from "~/utils/numbers";

function HTPayments() {
  const [selectedDate, setSelectedDate] = useState<{ month: number; year: number }>();
  const [selectedNurse, setSelectedNurse] = useState<Nurse>();
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [drawerDetailParams, setDrawerDetailParams] = useState<NursePaymentDetailParams>();
  const [nurses, setNurses] = useState<Nurse[]>([]);
  const [nurseSummary, setNurseSummary] = useState<NurseMonthSummary>();
  const [totalMonthAmount, setTotalMonthAmount] = useState<number>(0);
  const [country, setCountry] = useState<Locale>("cl");
  const [isCreatePaymentModalOpen, setIsCreatePaymentModalOpen] = useState<boolean>(false);
  const { isNursePaymentAdmin } = usePermissions();

  useEffect(() => {
    if (!isNursePaymentAdmin) {
      navigate("/");
    }
  }, []);

  const getAndSetNurseMonthSummary = async () => {
    if (selectedDate?.year && selectedNurse) {
      const res = await getNurseMonthSummary(selectedNurse.id, selectedDate?.month + 1, selectedDate.year);
      if ("error" in res) {
        Swal.fire({
          title: "Error al cargar resumen de enfermera",
          icon: "error",
        });
        return;
      }
      setNurseSummary(res);
    } else if (!selectedNurse) {
      setNurseSummary(undefined);
    }
  };

  useEffect(() => {
    getAndSetNurseMonthSummary();
  }, [selectedNurse, selectedDate]);

  const onSetCountryCode = (value: Locale) => {
    setSelectedDate(undefined);
    setSelectedNurse(undefined);
    setCountry(value);
  };

  return (
    <PrivateRoute>
      <PaymentFilters
        onSetSelectedDate={(value) => setSelectedDate(value)}
        onSetSelectedNurse={(value) => setSelectedNurse(value)}
        onSetCountry={onSetCountryCode}
        countryCode={country}
        selectedDate={selectedDate}
        nurses={nurses}
        setNurses={setNurses}
        selectedNurse={selectedNurse}
      />
      <div className="flex my-3 justify-between">
        <div className="flex items-center">
          <p className="mb-0 text-examedi-gray-3">Filtro aplicado:</p>
          {(selectedDate || selectedNurse) && (
            <p className="mb-0 ml-3">
              Pagos {selectedDate && `de ${monthNames[selectedDate.month]} del ${selectedDate.year}`}{" "}
              {selectedNurse && `de ${selectedNurse.names} ${selectedNurse.last_names}`}
            </p>
          )}
        </div>
        <CreateNursePaymentButton openCreateModal={() => setIsCreatePaymentModalOpen(true)} />
        <CreateNursePaymentModal
          isOpen={isCreatePaymentModalOpen}
          onClose={() => setIsCreatePaymentModalOpen(false)}
          nurses={nurses}
          setDrawerParams={setDrawerDetailParams}
          openDrawer={() => setIsDrawerOpen(true)}
        />
      </div>
      <div className="grid grid-cols-3 w-fit mb-3">
        <p className="mb-0 font-semibold">
          Total: ${numberWithPeriods(nurseSummary?.month_payment || totalMonthAmount, country)}
        </p>
        {nurseSummary && (
          <>
            <p className="mb-0 font-semibold">Efectivo: ${numberWithPeriods(nurseSummary.cash_payment, country)}</p>
            <p className="mb-0 font-semibold">
              Monto a transferir: ${numberWithPeriods(nurseSummary.pending_to_transfer, country)}
            </p>
          </>
        )}
      </div>
      <NursePaymentsTable
        selectedDate={selectedDate}
        selectedNurse={selectedNurse}
        countryCode={country}
        openDrawer={() => setIsDrawerOpen(true)}
        setDrawerDetailParams={setDrawerDetailParams}
        setTotalMonthAmount={setTotalMonthAmount}
      />
      <NursePaymentDetailDrawer
        isOpen={isDrawerOpen}
        config={drawerDetailParams}
        onClose={() => setIsDrawerOpen(false)}
      />
    </PrivateRoute>
  );
}

export default HTPayments;
