import React, { useEffect, useRef, useState } from "react";

import moment from "moment";
import Swal from "sweetalert2";

import { CollapsibleForwardRefProps } from "~/components/Common/Collapsible";
import useDrawerView from "~/components/hooks/useDrawerView";
import LoadingSpinnerIcon from "~/components/icons/LoadingSpinnerIcon";
import NursePaymentDetailCollapsible from "~/components/nursePayments/NursePaymentDetailCollapsible";
import CrossIcon from "~/icons/CrossIcon";
import { listNursePayments } from "~/utils/api/v2/nursePayments";
import { NursePayment, NursePaymentDetailParams } from "~/utils/interfaces/nursePayments";

type NursePaymentDetailDrawerProps = {
  isOpen: boolean;
  config?: NursePaymentDetailParams;
  onClose: () => void;
};

function NursePaymentDetailDrawer({ isOpen, config, onClose }: NursePaymentDetailDrawerProps) {
  const { content, open, close } = useDrawerView({
    direction: "right",
    className: "w-1/3 max-w-md !right-0 !left-auto",
    onClose: () => {
      onClose();
      document.getElementById("___gatsby")?.removeAttribute("style");
    },
  });
  const collapsibleRef = useRef<CollapsibleForwardRefProps[]>([]);
  const [appointmentNursePayments, setAppointmentNursePayments] = useState<NursePayment[]>([]);
  const [extraPayment, setExtraPayment] = useState<NursePayment>();
  const [loading, setLoading] = useState<boolean>(false);

  const handleCollapsibleClick = (i: number) => {
    if (collapsibleRef.current[i]?.isOpen()) {
      collapsibleRef.current[i]?.close();
      return;
    }
    collapsibleRef.current[i]?.open();
  };

  const getAndSetPayments = async () => {
    if (!config) return;
    setLoading(true);
    const nursePaymentsRes = await listNursePayments({
      nurseId: config.nurseId,
      beginDate: config.date,
      endDate: moment(config.date).add(1, "days").format("YYYY-MM-DD"),
    });
    if ("error" in nursePaymentsRes) {
      const errorMessage = nursePaymentsRes.error as string;
      Swal.fire({
        title: "Error",
        icon: "error",
        text: errorMessage,
      });
      setLoading(false);
      return;
    }
    setAppointmentNursePayments(nursePaymentsRes.results.filter((nursePayment) => !!nursePayment.appointment));
    const nonAppointmentPayments = nursePaymentsRes.results.filter((nursePayment) => !nursePayment.appointment);
    if (nonAppointmentPayments.length) {
      setExtraPayment(
        nonAppointmentPayments.reduce((acc, nursePayment) => {
          acc["gross_ht_payment"] += nursePayment.gross_ht_payment;
          const items = Object.entries(nursePayment.detail).filter(([_, amount]) => !!amount);
          items.forEach(([concept, amount]) => {
            if (concept in acc.detail) acc.detail[concept] += amount;
            else acc.detail[concept] = amount;
          });
          return acc;
        }),
      );
    } else {
      setExtraPayment(undefined);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAndSetPayments();
  }, [config]);

  useEffect(() => {
    if (!isOpen) return;
    open(
      <div className="bg-white h-full px-3 py-4 overflow-y-scroll">
        <div onClick={async () => close()}>
          <CrossIcon
            color="black"
            height={15}
            width={15}
            className="cursor-pointer"
          />
        </div>
        <p className="py-6 text-lg">
          Pagos del {config?.date} - de {config?.nurseName}
        </p>
        {!loading && (
          <>
            {appointmentNursePayments.map((nursePayment, i) => (
              <NursePaymentDetailCollapsible
                nursePayment={nursePayment}
                key={i}
                id={i}
                ref={(element) => (collapsibleRef.current[i] = element as CollapsibleForwardRefProps)}
                onClick={() => handleCollapsibleClick(i)}
                date={config?.date}
              />
            ))}
            {!!extraPayment && (
              <NursePaymentDetailCollapsible
                nursePayment={extraPayment}
                key="extraPaymentsCollapsible"
                ref={(element) =>
                  (collapsibleRef.current[appointmentNursePayments.length] = element as CollapsibleForwardRefProps)
                }
                onClick={() => handleCollapsibleClick(appointmentNursePayments.length)}
                date={config?.date}
              />
            )}
          </>
        )}
        {loading && <LoadingSpinnerIcon className="mx-auto" />}
      </div>,
    );
    const backDiv = document.getElementById("___gatsby");
    if (!backDiv) return;
    backDiv.style.filter = "blur(2px)";
  }, [isOpen, appointmentNursePayments, extraPayment, loading]);

  return <>{content}</>;
}

export default NursePaymentDetailDrawer;
