import React, { useState } from "react";

import Swal from "sweetalert2";

import Button from "~/components/Buttons/Button";
import InputDate from "~/components/inputs/InputDate";
import InputNumber from "~/components/inputs/InputNumber";
import InputSelect from "~/components/inputs/InputSelect";
import InputText from "~/components/inputs/InputText";
import { createNursePayments } from "~/utils/api/v2/nursePayments";
import { Nurse } from "~/utils/interfaces/Nurse";
import { NursePaymentDetailParams } from "~/utils/interfaces/nursePayments";

type CreateNursePaymentModalBodyProps = {
  close: () => void;
  nurses: Nurse[];
  setDrawerParams: (params: NursePaymentDetailParams) => void;
  openDrawer: () => void;
};

function CreateNursePaymentModalBody({ close, nurses, setDrawerParams, openDrawer }: CreateNursePaymentModalBodyProps) {
  const [paymentName, setPaymentName] = useState<string>("");
  const [paymentAmount, setPaymentAmount] = useState<number>(0);
  const [paymentDate, setPaymentDate] = useState<string>("");
  const [selectedNurseId, setSelectedNurseId] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = async () => {
    if (!areAllInputsValid()) return;
    setLoading(true);
    const res = await createNursePayments({
      name: paymentName,
      amount: paymentAmount,
      date: paymentDate,
      nurseId: selectedNurseId,
    });
    if ("error" in res) {
      const errorMessage = res.error as string;
      Swal.fire({
        title: "Error",
        icon: "error",
        text: errorMessage,
      });
      setLoading(false);
      return;
    }
    Swal.fire({
      title: "Pago creado",
      icon: "success",
      text: "El pago se ha creado correctamente",
    });
    setLoading(false);
    const nurse = nurses.find((nurse) => nurse.id === selectedNurseId);
    setDrawerParams({
      nurseName: nurse?.names + " " + nurse?.last_names || "",
      date: paymentDate,
      nurseId: selectedNurseId,
    });
    openDrawer();
    close();
  };

  const areAllInputsValid = () => {
    return !!paymentName && !!paymentAmount && !!paymentDate && !!selectedNurseId;
  };

  return (
    <div>
      <label
        htmlFor="nurseSelector"
        className="text-base font-medium mb-2"
      >
        Selecciona la enfermera
      </label>
      <InputSelect
        options={nurses.map((nurse) => ({ key: nurse.id, value: nurse.names + " " + nurse.last_names }))}
        optionClassName="text-black"
        allowSearch
        onChange={(option) => setSelectedNurseId(option?.key as string)}
      />
      <label
        htmlFor="paymentName"
        className="text-base font-medium mb-2 mt-3"
      >
        Dale un nombre al pago
      </label>
      <InputText
        id="paymentName"
        name="paymentName"
        placeholder="Ej: Guardar Insumos"
        value={paymentName}
        onChange={(e) => setPaymentName(e.target.value)}
      />
      <label
        htmlFor="paymentAmount"
        className="text-base font-medium mb-2 mt-3"
      >
        Escribe el monto del pago
      </label>
      <InputNumber
        name="paymentAmount"
        placeholder="Ej: 100000"
        value={paymentAmount}
        onChange={(e) => setPaymentAmount(parseInt(e.target.value))}
      />
      <label
        htmlFor="paymentDate"
        className="text-base font-medium mb-2 mt-3"
      >
        Selecciona la fecha del pago
      </label>
      <InputDate
        id="paymentDate"
        name="paymentDate"
        placeholder="Ej: 2021-09-01"
        value={paymentDate}
        onChange={(e) => setPaymentDate(e.target.value)}
      />

      <div className="flex flex-row my-8">
        <Button
          className="blue w-full mr-6"
          text="Guardar Pago"
          disabled={!areAllInputsValid()}
          isLoading={loading}
          onClick={onSubmit}
        />
        <Button
          className="white w-full"
          text="Salir sin guardar"
          onClick={close}
        />
      </div>
    </div>
  );
}

export default CreateNursePaymentModalBody;
