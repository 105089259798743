export const nursePaymentDetailDisplayName = {
  items: "Bono por exámenes",
  total: "Total",
  patient: "Pacientes",
  delivery: "Domicilio",
  operative: "Operativo",
  frustrated: "Frustrado",
  time_sector: "Bono por Sector",
  auction: "Bono por Subasta",
};
