import React from "react";

import Modal from "~/components/Common/Modal";
import CreateNursePaymentModalBody from "~/components/nursePayments/CreateNursePaymentModalBody";
import CreateNursePaymentModalHeader from "~/components/nursePayments/CreateNursePaymentModalHeader";
import { Nurse } from "~/utils/interfaces/Nurse";
import { NursePaymentDetailParams } from "~/utils/interfaces/nursePayments";

type CreateNursePaymentModalProps = {
  isOpen: boolean;
  onClose: () => void;
  nurses: Nurse[];
  setDrawerParams: (params: NursePaymentDetailParams) => void;
  openDrawer: () => void;
};

function CreateNursePaymentModal({
  isOpen,
  onClose,
  nurses,
  setDrawerParams,
  openDrawer,
}: CreateNursePaymentModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      header={<CreateNursePaymentModalHeader close={onClose} />}
      content={
        <CreateNursePaymentModalBody
          close={onClose}
          nurses={nurses}
          setDrawerParams={setDrawerParams}
          openDrawer={openDrawer}
        />
      }
      hideFooter
    />
  );
}

export default CreateNursePaymentModal;
