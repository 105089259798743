import React, { useEffect, useMemo, useRef, useState } from "react";

import moment from "moment";
import Swal from "sweetalert2";

import Tabs from "~/components/Common/Tabs";
import InputSelect, { InputSelectForwardRefProps } from "~/components/inputs/InputSelect";
import { listNursesPerCountry } from "~/utils/api/v2/nurse";
import { getMonthsWithPayments } from "~/utils/api/v2/nursePayments";
import { CODE_TO_COUNTRY, COUNTRY_CODES } from "~/utils/data/constants";
import { Locale } from "~/utils/interfaces/Locale";
import { Nurse } from "~/utils/interfaces/Nurse";
import { MonthsWithPayments } from "~/utils/interfaces/nursePayments";

type PaymentsFiltersProps = {
  onSetSelectedNurse: (value: Nurse | undefined) => void;
  onSetSelectedDate: (value: MonthsWithPayments) => void;
  onSetCountry: (value: Locale) => void;
  countryCode: Locale;
  selectedDate?: MonthsWithPayments;
  nurses: Nurse[];
  setNurses: (value: Nurse[]) => void;
  selectedNurse?: Nurse;
};

function PaymentFilters({
  onSetSelectedNurse,
  onSetSelectedDate,
  onSetCountry,
  selectedDate,
  countryCode,
  nurses,
  setNurses,
  selectedNurse,
}: PaymentsFiltersProps) {
  const [availableMonths, setAvailableMonths] = useState<MonthsWithPayments[]>([]);
  const nurseInputRef = useRef<InputSelectForwardRefProps>(null);
  const dateInputRef = useRef<InputSelectForwardRefProps>(null);

  const monthOptions = useMemo(
    () =>
      availableMonths
        .sort((date1, date2) => `${date2.year}-${date2.month}`.localeCompare(`${date1.year}-${date1.month}`))
        .map((monthYear) => ({
          key: JSON.stringify(monthYear),
          value: moment().month(monthYear.month).year(monthYear.year).format("MMMM YYYY"),
        })),
    [availableMonths],
  );
  const getAndSetAvailableMonthsAndNurses = async () => {
    const [res, nurses] = await Promise.all([
      getMonthsWithPayments(countryCode),
      listNursesPerCountry({ countryCode, active: true }),
    ]);
    if ("error" in res || "error" in nurses) {
      let errorMessage = "error" in res ? res.error : "";
      errorMessage = "error" in nurses ? nurses.error : errorMessage;
      Swal.fire({
        title: "Error",
        icon: "error",
        text: errorMessage,
      });
      return;
    }
    const formatedRes = res.map((item) => ({ ...item, month: item.month - 1 }));
    setAvailableMonths(formatedRes);
    if (formatedRes.length > 0) {
      onSetSelectedDate(formatedRes[0]);
    }
    setNurses(nurses);
  };

  useEffect(() => {
    getAndSetAvailableMonthsAndNurses();
  }, [countryCode]);

  useEffect(() => {
    if (!selectedNurse) {
      nurseInputRef.current?.reset();
    }
    if (!selectedDate) {
      dateInputRef.current?.reset();
    }
  }, [selectedNurse, selectedDate]);

  return (
    <>
      <Tabs
        elements={COUNTRY_CODES}
        parser={(value: string) => CODE_TO_COUNTRY[value as Locale]}
        onSelectTab={(value: string) => onSetCountry(value as Locale)}
      />
      <div className="flex items-center pb-2 border-b border-examedi-gray-4">
        <p className="font-semibold text-lg my-0">Filtros:</p>
        <InputSelect
          options={monthOptions}
          className="!w-60 mx-4"
          optionClassName="text-black"
          onChange={(option) => onSetSelectedDate(JSON.parse(option?.key || "{}"))}
          placeholder="Selecciona un mes"
          value={JSON.stringify(selectedDate)}
          ref={dateInputRef}
        />
        <InputSelect
          options={nurses.map((nurse) => ({ key: nurse.id, value: nurse.names + " " + nurse.last_names }))}
          className="!w-fit mx-4"
          optionClassName="text-black"
          allowSearch
          allowClear
          onChange={(option) => onSetSelectedNurse(nurses.find((nurse) => nurse.id === option?.key))}
          placeholder="Selecciona una enfermera"
          ref={nurseInputRef}
        />
      </div>
    </>
  );
}

export default PaymentFilters;
