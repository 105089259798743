import React, { forwardRef, useEffect, useImperativeHandle, useRef } from "react";

import clsx from "clsx";

const DEFAULT_DELAY_MS = 150;

export type CollapsibleForwardRefProps = {
  open: () => void;
  close: () => void;
  toggle: () => void;
  isOpen: () => boolean;
  ref: () => HTMLDivElement | null;
};

type CollapsibleCommonProps = {
  head: React.ReactElement;
  initialState?: boolean;
  className?: string;
  headClassName?: string;
  contentClassName?: string;
  innerContentRef?: React.MutableRefObject<HTMLDivElement | null>;
  delayMs?: number;
  onBeforeOpen?: () => void;
  onOpen?: () => void;
  onBeforeClose?: () => void;
  onClose?: () => void;
  onBeforeChange?: (value: boolean) => void;
  onChange?: (value: boolean) => void;
};

type CollapsibleAlternativeProps =
  | {
      children: React.ReactElement;
      content?: never;
    }
  | {
      children?: never;
      content: React.ReactElement;
    };

type CollapsibleProps = CollapsibleCommonProps & CollapsibleAlternativeProps;

const Collapsible = forwardRef<CollapsibleForwardRefProps, CollapsibleProps>(function Collapsible(
  {
    children,
    head,
    content,
    initialState,
    className,
    headClassName,
    contentClassName,
    innerContentRef,
    delayMs,
    onBeforeOpen,
    onOpen,
    onBeforeClose,
    onClose,
    onBeforeChange,
    onChange,
  }: CollapsibleProps,
  ref,
) {
  const contentRef = useRef<HTMLDivElement | null>(null);
  const CollapsibleState = useRef<boolean>(false);
  const elementRef = useRef<HTMLDivElement | null>(null);

  const delay = delayMs || DEFAULT_DELAY_MS;

  useImperativeHandle(ref, () => ({
    open,
    close,
    toggle,
    isOpen,
    ref: () => elementRef.current,
  }));

  function open() {
    onBeforeOpen?.();
    onBeforeChange?.(true);
    handleOpen();
    setTimeout(() => {
      onOpen?.();
      onChange?.(true);
    }, delay);
  }

  function close() {
    onBeforeClose?.();
    onBeforeChange?.(false);
    handleClose();
    setTimeout(() => {
      onClose?.();
      onChange?.(false);
    }, delay);
  }

  function isOpen() {
    return CollapsibleState.current;
  }

  function toggle() {
    if (CollapsibleState.current) {
      close();
    } else {
      open();
    }
  }

  function handleOpen() {
    const child = contentRef.current?.firstElementChild;
    if (child) {
      CollapsibleState.current = true;
      contentRef.current!.style.height = child.clientHeight + 2 + "px";
      contentRef.current!.style.opacity = "1";
    }
  }

  function handleClose() {
    CollapsibleState.current = false;
    contentRef.current!.style.height = "0px";
    contentRef.current!.style.opacity = "0";
  }

  useEffect(() => {
    const element = contentRef.current;
    if (element) {
      if (initialState) {
        handleOpen();
      } else {
        handleClose();
      }
      setTimeout(() => {
        element.style.transition = `height ${delay}ms ease-in-out, opacity 100ms linear 50ms`;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section
      ref={elementRef}
      className={clsx("w-full", className)}
    >
      <div className={clsx("w-full", headClassName)}>{head}</div>
      <div
        ref={(value) => {
          contentRef.current = value;
          if (innerContentRef) {
            innerContentRef.current = value;
          }
        }}
        className={clsx("w-full overflow-y-hidden", contentClassName)}
      >
        {children || content}
      </div>
    </section>
  );
});

export default React.memo(Collapsible);
