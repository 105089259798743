import { SupportedCountry } from "~/utils/constants/places";
import { Locale } from "~/utils/interfaces/Locale";

export const COUNTRIES: SupportedCountry[] = ["Chile", "México"];

export const countriesNoTilde = ["Chile", "Mexico"];

export const COUNTRY_CODES: Locale[] = ["cl", "mx"];

export const CODE_TO_COUNTRY: Record<Locale, SupportedCountry> = { cl: "Chile", mx: "México" };

export const COUNTRY_TO_CODE: Record<SupportedCountry, Locale> = { Chile: "cl", México: "mx" };

export const TIMEZONE_TO_COUNTRY = {
  "America/Santiago": "Hora de Chile",
  "America/Mexico_City": "Hora de México",
};

export const COUNTRY_TO_TIMEZONE = {
  Chile: "America/Santiago",
  México: "America/Mexico_City",
};

export const DEFAULT_TIMEZONE = "America/Santiago";

export const SUBSCRIPTIONS_CATEGORIES = ["Todas", "exanutri"];
