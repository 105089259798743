export const capitalize = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const removeAccents = (value: string) => {
  return value
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/đ/g, "d")
    .replace(/Đ/g, "D");
};

export const insuranceNameText = (text: string) => {
  switch (text) {
    case "cruzblanca":
      return "Cruz Blanca";
    case "consalud":
      return "Consalud";
    case "Banmedica":
      return "Banmedica";
    case "colmena":
      return "Colmena";
    case "nueva_masvida":
      return "Nueva Masvida";
    case "vida_tres":
      return "Vida Tres";
    case "particular":
      return "Particular";
    default:
      return "Particular";
  }
};
