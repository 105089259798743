import React from "react";

import CrossIcon from "~/icons/CrossIcon";

type CreateNursePaymentModalHeaderProps = {
  close: () => void;
};

function CreateNursePaymentModalHeader({ close }: CreateNursePaymentModalHeaderProps) {
  return (
    <div>
      <div className="flex justify-end">
        <CrossIcon
          color="black"
          height={15}
          width={15}
          className="cursor-pointer"
          onClick={close}
        />
      </div>
      <p className="text-xl font-medium mb-0 py-4 border-b">Crear Pago</p>
    </div>
  );
}

export default CreateNursePaymentModalHeader;
