import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";

import clsx from "clsx";

import CrossCircleIcon from "~/components/icons/CrossCircleIcon";
import GreaterThanArrowIcon from "~/components/icons/GreaterThanArrowSmallIcon";
import LoadingSpinnerIcon from "~/components/icons/LoadingSpinnerIcon";
import MagnifyingGlassIcon from "~/components/icons/MagnifyingGlassIcon";
import { removeAccents } from "~/utils/texts";

export type SelectOption = {
  key: string;
  value: string;
};

export type InputSelectForwardRefProps = {
  open: () => void;
  close: () => void;
  reset: () => void;
};

export type SelectProps = {
  id?: string;
  value?: string;
  options: SelectOption[];
  placeholder?: string;
  disabled?: boolean;
  isLoading?: boolean;
  onChange?: (e: SelectOption | undefined) => void;
  onBlur?: (e: string) => void;
  className?: string;
  optionContainerClass?: string;
  optionClassName?: string;
  allowClear?: boolean;
  allowSearch?: boolean;
  resetOnSelect?: boolean;
  placeholderClassName?: string;
  containerClassName?: string;
  lowerCasePlaceholder?: boolean;
  selectionClassName?: string;
  selectedClassName?: string;
};

/**
 * @param resetOnSelect
 * Set to true if you only care about provided onChange handler
 * and want to keep the placeholder always unless focused
 */
const InputSelect = forwardRef<InputSelectForwardRefProps, SelectProps>(function InputSelect(
  {
    id,
    value,
    options,
    placeholder,
    disabled,
    isLoading,
    onChange,
    onBlur,
    className,
    optionContainerClass,
    optionClassName,
    allowClear,
    allowSearch,
    resetOnSelect,
    placeholderClassName,
    containerClassName,
    lowerCasePlaceholder,
    selectionClassName,
    selectedClassName,
  },
  ref,
) {
  const [selected, setSelected] = useState<SelectOption>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [filteredOptions, setFilteredOptions] = useState<SelectOption[]>();
  const [inputValue, setInputValue] = useState<string>();
  const [focusedOption, setFocusedOption] = useState<SelectOption>();
  const containerRef = useRef<HTMLDivElement>(null);
  const optionsRef = useRef<HTMLDivElement>(null);

  useImperativeHandle(ref, () => ({
    open: openSelect,
    close: closeSelect,
    reset: () => {
      setSelected(undefined);
      setFocusedOption(undefined);
      setInputValue(undefined);
    },
  }));

  function openSelect() {
    if (disabled || isLoading) {
      return;
    }
    if (selected) {
      setFocusedOption(selected);
    }
    setIsOpen(true);
  }

  function closeSelect() {
    setInputValue("");
    setFilteredOptions(options);
    setIsOpen(false);
    containerRef.current?.focus();
  }

  function removeSelection(e: React.MouseEvent) {
    e.preventDefault();
    e.stopPropagation();
    setSelected(undefined);
    setFocusedOption(undefined);
    onChange?.(undefined);
    closeSelect();
  }

  function addSelection(value: SelectOption) {
    if (resetOnSelect) {
      setSelected(undefined);
      setFocusedOption(undefined);
    } else {
      setSelected(value);
      setFocusedOption(value);
    }
    onChange?.(value);
    setIsOpen(false);
  }

  function handleArrowDown() {
    if (optionsRef.current && filteredOptions && filteredOptions.length > 0) {
      if (!focusedOption) {
        setFocusedOption(filteredOptions[0]);
      } else {
        const currentIndex = filteredOptions.findIndex((opt) => opt.key === focusedOption.key);
        if (filteredOptions.length === currentIndex + 1) {
          setFocusedOption(filteredOptions[0]);
          optionsRef.current.scrollTo({ top: 0 });
          return;
        } else {
          const next = filteredOptions[currentIndex + 1];
          const nextOptionRef = optionsRef.current.children[currentIndex + 1] as HTMLDivElement;
          const isVisible =
            nextOptionRef.offsetTop + nextOptionRef.clientHeight - optionsRef.current.scrollTop <
            optionsRef.current.clientHeight;
          if (!isVisible) {
            const outBy =
              nextOptionRef.offsetTop +
              nextOptionRef.clientHeight -
              optionsRef.current.clientHeight -
              optionsRef.current.scrollTop;
            optionsRef.current.scrollTo({ top: optionsRef.current.scrollTop + outBy });
          }
          setFocusedOption(next);
        }
      }
    }
  }

  function handleArrowUp() {
    if (optionsRef.current && filteredOptions && filteredOptions.length > 0) {
      if (!focusedOption) {
        setFocusedOption(filteredOptions[filteredOptions.length - 1]);
        optionsRef.current.scrollTo({ top: optionsRef.current.scrollHeight });
      } else {
        const currentIndex = filteredOptions.findIndex((opt) => {
          return opt.key === focusedOption.key;
        });
        if (currentIndex === 0) {
          setFocusedOption(filteredOptions[filteredOptions.length - 1]);
          optionsRef.current.scrollTo({ top: optionsRef.current.scrollHeight });
        } else {
          const prev = filteredOptions[currentIndex - 1];
          const prevOptionRef = optionsRef.current.children[currentIndex - 1] as HTMLDivElement;
          const isVisible =
            optionsRef.current.scrollTop < prevOptionRef.offsetTop &&
            prevOptionRef.offsetTop < optionsRef.current.scrollTop + optionsRef.current.clientHeight;
          if (!isVisible) {
            optionsRef.current.scrollTo({ top: prevOptionRef.offsetTop });
          }
          setFocusedOption(prev);
        }
      }
    }
  }

  function onClickSelectHandler(e: React.MouseEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    if (isOpen) {
      closeSelect();
    } else {
      openSelect();
    }
  }

  function onChangeHandler(e: React.ChangeEvent<HTMLInputElement>) {
    setInputValue(e.target.value);
    onBlur?.(e.target.value);
  }

  function onContainerKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter" || e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();
      e.stopPropagation();
      if (!isOpen) {
        openSelect();
      }
      if (e.key === "ArrowDown") {
        handleArrowDown();
        return;
      }
      if (e.key === "ArrowUp") {
        handleArrowUp();
        return;
      }
    }
    if (e.key === "Tab") {
      if (focusedOption) {
        addSelection(focusedOption);
      }
    }
  }

  function onInputKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (optionsRef.current && ["Enter", "Escape", "ArrowDown", "ArrowUp"].includes(e.key)) {
      e.preventDefault();
      e.stopPropagation();
      switch (e.key) {
        case "Enter":
          if (filteredOptions && filteredOptions.length > 0) {
            if (focusedOption) {
              addSelection(focusedOption);
              setIsOpen(false);
              return;
            }
            if (selected) {
              closeSelect();
            }
          }
          break;
        case "ArrowDown":
          handleArrowDown();
          return;
        case "ArrowUp":
          handleArrowUp();
          return;
      }
      closeSelect();
    }
  }

  function clickOutsideHandler(e: MouseEvent | TouchEvent) {
    const currentRef = containerRef?.current;
    if (currentRef && currentRef.contains(e.target as Node)) {
      return;
    }
    setFocusedOption(selected);
    closeSelect();
  }

  useEffect(() => {
    const filtered = options?.filter((opt) =>
      removeAccents(opt.value.toLowerCase()).includes(removeAccents(inputValue?.toLowerCase() || "")),
    );
    setFilteredOptions(filtered);
  }, [inputValue, options]);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", clickOutsideHandler);
      document.addEventListener("touchstart", clickOutsideHandler);
    } else {
      document.removeEventListener("mousedown", clickOutsideHandler);
      document.removeEventListener("touchstart", clickOutsideHandler);
    }
    return () => {
      document.removeEventListener("mousedown", clickOutsideHandler);
      document.removeEventListener("touchstart", clickOutsideHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (value && options) {
      const opt = options.find((op) => op.key === value);
      if (opt) {
        setSelected(opt);
        setFocusedOption(opt);
      } else {
        setSelected(undefined);
        setFocusedOption(undefined);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, options]);

  useEffect(() => {
    if (filteredOptions && filteredOptions.length === 1) {
      setFocusedOption(filteredOptions[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredOptions]);

  return (
    <div
      id={id}
      ref={containerRef}
      tabIndex={0}
      className={clsx("h-full w-full", "max-h-[2.8rem]", "my-2", "relative", "cursor-pointer", className)}
      onKeyDown={onContainerKeyDown}
    >
      <div
        onClick={onClickSelectHandler}
        className={clsx(
          "h-full min-h-[2.5rem] w-full",
          "bg-white",
          "pl-3",
          "py-[0.7rem]",
          "flex items-center",
          "border border-g4",
          "rounded-md",
          !disabled && !isLoading && "active:border-examedi-gray-4",
          !selected?.value && "text-examedi-gray-2",
          isOpen && "rounded-b-none border-examedi-gray-4",
          isOpen && allowSearch && "!text-examedi-gray-2",
          disabled && "!border-g4/50 cursor-not-allowed !bg-g5 text-g3",
          isLoading && "cursor-progress",
          containerClassName,
        )}
      >
        <div className={clsx("h-full w-full", "flex flex-grow", "line-clamp-1", "text-ellipsis")}>
          {allowSearch && isOpen && (
            <div className={clsx("h-full w-full", "flex items-center")}>
              <input
                type="text"
                autoFocus
                className={clsx(
                  "h-full w-full",
                  "border-0",
                  "px-0",
                  "focus:ring-0",
                  "focus:outline-none",
                  "placeholder:capitalize",
                  "disabled:!text-g3",
                  "disabled:placeholder:!text-g4",
                )}
                onChange={onChangeHandler}
                onKeyDown={onInputKeyDown}
                placeholder={placeholder || selected?.value || "Seleccione"}
              />
            </div>
          )}
          {(!allowSearch || (allowSearch && !isOpen)) && (
            <p
              className={clsx(
                "h-full w-full my-0",
                "flex items-center",
                !lowerCasePlaceholder && "capitalize",
                disabled && "text-g3",
                selected?.value && selectedClassName,
                !selected?.value && placeholderClassName,
                selectionClassName,
              )}
            >
              {selected?.value || placeholder || "Seleccione"}
            </p>
          )}
        </div>
        {isLoading && (
          <div className={clsx("h-full min-w-[2rem]", "flex flex-grow-0 items-center justify-center")}>
            <LoadingSpinnerIcon />
          </div>
        )}
        {!isLoading && isOpen && allowSearch && (
          <div className={clsx("h-full min-w-[2rem]", "flex flex-grow-0 items-center justify-center")}>
            <MagnifyingGlassIcon color="gray" />
          </div>
        )}
        {!isLoading && isOpen && !allowSearch && (
          <div className={clsx("h-full min-w-[2rem]", "flex flex-grow-0 items-center justify-center")}>
            <GreaterThanArrowIcon
              className="-rotate-90 scale-125"
              color={disabled ? "#A7A7A7" : undefined}
            />
          </div>
        )}
        {!isLoading && !isOpen && !allowClear && (
          <div className={clsx("h-full min-w-[2rem]", "flex flex-grow-0 items-center justify-center")}>
            <GreaterThanArrowIcon
              className="rotate-90 scale-125"
              color={disabled ? "#A7A7A7" : undefined}
            />
          </div>
        )}
        {!isLoading && !isOpen && allowClear && (
          <div
            className={clsx("h-full", "group")}
            onClick={removeSelection}
          >
            <div
              className={clsx(
                "h-full min-w-[2rem]",
                "flex flex-grow-0 items-center justify-center",
                selected && "group-hover:hidden",
              )}
            >
              <GreaterThanArrowIcon className="rotate-90 scale-125" />
            </div>
            <div
              className={clsx(
                "h-full min-w-[2rem]",
                "flex flex-grow-0 items-center justify-center",
                "hidden",
                selected && "group-hover:z-40 group-hover:flex",
              )}
            >
              <CrossCircleIcon
                className="scale-75 cursor-pointer"
                stroke="gray"
              />
            </div>
          </div>
        )}
      </div>
      <div
        ref={optionsRef}
        className={clsx(
          "h-auto w-full",
          "!bg-white",
          "absolute z-30",
          isOpen ? "block" : "hidden",
          "border-g4/50  border-x border-b",
          "rounded-b-md",
          "shadow-md",
          "max-h-40 overflow-y-auto",
          optionContainerClass,
        )}
      >
        {filteredOptions?.map((opt) => {
          const { key, value } = opt;
          return (
            <div
              key={key}
              className={clsx(
                "w-full",
                "px-3 py-2 md:py-[0.5rem]",
                "!bg-white hover:bg-gray-100 active:shadow-inner",
                focusedOption?.key === key && "!bg-examedi-blue-strong-75 text-examedi-white-light",
                "cursor-pointer",
                "capitalize",
                "focus:!border-examedi-blue-strong",
                optionClassName,
              )}
              onClick={() => addSelection(opt)}
              onMouseEnter={() => setFocusedOption(opt)}
            >
              {value}
            </div>
          );
        })}
      </div>
    </div>
  );
});

export default React.memo(InputSelect);
