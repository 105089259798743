import React, { useEffect, useRef } from "react";

import clsx from "clsx";

type InputTextProps = {
  id: string;
  name: string;
  placeholder?: string;
  value: string;
  className?: string | null | false;
  disabled?: boolean;
  maxLength?: number;
  pattern?: string;
  focusOnMount?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  parser?: (value: string) => string;
  onKeyUp?: React.KeyboardEventHandler<HTMLInputElement> | undefined;
  onPaste?: React.ClipboardEventHandler<HTMLInputElement> | undefined;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
};

function InputText({
  id,
  className,
  name,
  placeholder,
  disabled,
  value,
  maxLength,
  pattern,
  focusOnMount,
  onChange,
  onBlur,
  parser,
  onKeyUp,
  onPaste,
  onFocus,
}: InputTextProps): JSX.Element {
  const inputElementRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (focusOnMount && inputElementRef.current) {
      inputElementRef.current.focus();
    }
  }, [focusOnMount]);

  return (
    <input
      ref={inputElementRef}
      type="text"
      className={clsx(
        "w-full",
        "py-[0.7rem] px-[0.8rem]",
        "text-sm",
        "bg-white",
        "rounded-lg border border-g4",
        "placeholder:text-examedi-gray-regular",
        "placeholder:text-sm",
        "focus:ring-0",
        "focus:outline-none",
        "focus:shadow-none",
        "focus:placeholder:text-transparent",
        "focus:!border-examedi-blue-strong",
        "focus:!text-examedi-gray-2",
        "transition-colors duration-200",
        "disabled:!cursor-not-allowed",
        "disabled:placeholder:text-g4",
        "disabled:!text-g3",
        "disabled:!bg-g5",
        "disabled:!border-g4/50",
        className,
      )}
      id={id}
      name={name}
      /**
       * When reusing user data, second name & second last name may be empty so avoid placeholder
       */
      placeholder={placeholder}
      disabled={disabled}
      onChange={(ev) => {
        const parsedEvent = ev;
        if (parser) {
          parsedEvent.target.value = parser(parsedEvent.target.value);
        }
        return onChange(parsedEvent);
      }}
      onBlur={onBlur}
      value={value}
      maxLength={maxLength}
      pattern={pattern || undefined}
      onKeyUp={onKeyUp}
      onPaste={onPaste}
      onFocus={onFocus}
    />
  );
}

export default React.memo(InputText);
