import React from "react";

function GreaterThanArrowIcon(props: React.ComponentPropsWithoutRef<"svg"> & { color?: string | undefined }) {
  return (
    <svg
      width="6"
      height="9"
      viewBox="0 0 6 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 7.66667L4.33333 4.33333L1 1"
        stroke={props.color || "#191A18"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default GreaterThanArrowIcon;
