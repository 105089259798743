import React from "react";

function CrossIcon(props: React.ComponentPropsWithoutRef<"svg"> & { color?: string }) {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.488646 0.488401C0.170777 0.80627 0.170777 1.32164 0.488646 1.63951L3.94199 5.09285L0.488681 8.54616C0.170812 8.86403 0.170812 9.3794 0.488681 9.69727C0.806549 10.0151 1.32192 10.0151 1.63978 9.69727L5.0931 6.24396L8.54637 9.69723C8.86424 10.0151 9.37961 10.0151 9.69748 9.69723C10.0153 9.37936 10.0153 8.864 9.69748 8.54613L6.2442 5.09285L9.69751 1.63954C10.0154 1.32167 10.0154 0.806305 9.69751 0.488436C9.37964 0.170568 8.86428 0.170568 8.54641 0.488436L5.0931 3.94175L1.63975 0.488401C1.32188 0.170533 0.806514 0.170533 0.488646 0.488401Z"
        fill={props.color || "white"}
      />
    </svg>
  );
}

export default CrossIcon;
