import React from "react";

import PlusIcon from "~/components/icons/PlusIcon";

type CreateNursePaymentButtonProps = {
  openCreateModal: () => void;
};

function CreateNursePaymentButton({ openCreateModal }: CreateNursePaymentButtonProps) {
  return (
    <div
      className="flex px-4 py-2 border-2 border-examedi-blue-strong rounded-lg cursor-pointer"
      onClick={openCreateModal}
    >
      <PlusIcon fill="#457DF1" />
      <p className="ml-2 mb-0 text-examedi-blue-strong">Crear Pago</p>
    </div>
  );
}

export default CreateNursePaymentButton;
