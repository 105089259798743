import React, { useEffect, useRef } from "react";

import clsx from "clsx";

type ModalProps = {
  isOpen: boolean;
  header: React.ReactNode;
  content: React.ReactNode;
  footer?: React.ReactNode;
  hideFooter?: boolean;
  className?: string;
  titleClassName?: string;
  contentClassName?: string;
  footerClassName?: string;
  onConfirm?: () => void | Promise<void>;
  onClose?: () => void;
  onSetIsOpen?: (value: boolean) => void;
};

const Modal: React.FC<ModalProps> = ({
  isOpen,
  header,
  content,
  footer,
  hideFooter,
  className,
  titleClassName,
  contentClassName,
  footerClassName,
  onConfirm,
  onClose,
  onSetIsOpen,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);

  const handleCancel = () => {
    onClose?.();
    onSetIsOpen?.(false);
  };

  const handleConfirm = async () => {
    await onConfirm?.();
    onSetIsOpen?.(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onSetIsOpen?.(false);
      }
    };

    if (isOpen) {
      document.body.style.overflow = "hidden";
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.body.style.overflow = "unset";
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  const defaultFooter = (
    <div className="flex justify-end space-x-4">
      <button
        className="px-4 py-2 rounded shadow text-white bg-examedi-red-strong hover:bg-examedi-red-light"
        onClick={handleCancel}
      >
        Cancelar
      </button>
      <button
        className="px-4 py-2 rounded shadow text-white bg-examedi-blue-strong hover:bg-examedi-blue-strong-75"
        onClick={handleConfirm}
      >
        Confirmar
      </button>
    </div>
  );

  return (
    <div
      className={clsx("fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 backdrop-blur-md")}
    >
      <div
        ref={modalRef}
        className={clsx("w-3/4 max-w-3xl", "relative", "bg-white rounded-lg shadow-lg overflow-hidden", className)}
      >
        <div className={clsx("px-6 py-3 text-2xl font-light", titleClassName)}>{header}</div>
        <div className={clsx("px-6 py-2", contentClassName)}>{content}</div>
        {!hideFooter && <div className={clsx("p-6 border-t", footerClassName)}>{footer || defaultFooter}</div>}
      </div>
    </div>
  );
};

export default Modal;
