/**
 * For more details
 * https://www.notion.so/examedi/Button-0e967637b4ff449297e8b77d677ee895
 */

import React from "react";

import clsx from "clsx";

import LoadingSpinnerIcon from "~/components/icons/LoadingSpinnerIcon";

type ButtonContentProps = {
  text?: string;
  icon?: JSX.Element | null;
  className?: string;
  contentClassName?: string;
  textClassName?: string;
  iconClassName?: string;
};

export function ButtonContent({ text, icon, className, textClassName, iconClassName }: ButtonContentProps) {
  return (
    <div className={clsx("flex w-full items-center justify-center", icon && "gap-x-2", className)}>
      {!!icon && (
        <div className="w-fit">
          {React.cloneElement(icon, {
            className: clsx(
              (icon.props as { className: string }).className,
              "h-5 w-5 group-disabled:stroke-white",
              iconClassName,
            ),
          })}
        </div>
      )}
      {!!text && <div className={clsx("group-disabled:text-white", icon && "w-fit", textClassName)}>{text}</div>}
    </div>
  );
}

export type ButtonBasicProps = {
  isLoading?: boolean;
  disabled?: boolean;
  onClick?: (arg?: React.MouseEvent<HTMLButtonElement, MouseEvent> | any) => void;
  className?: string;
};

export type ButtonProps = ButtonContentProps & ButtonBasicProps;

function Button({ text, icon, isLoading, disabled, onClick, className, textClassName, iconClassName }: ButtonProps) {
  const onClickWrapper = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onClick?.(e);
  };

  return (
    <button
      type={onClick ? "button" : "submit"}
      disabled={disabled}
      onClick={onClickWrapper}
      className={clsx("button group", isLoading && "loading", className)}
    >
      {isLoading && <LoadingSpinnerIcon />}
      {!isLoading && (
        <ButtonContent
          text={text}
          icon={icon}
          textClassName={textClassName}
          iconClassName={iconClassName}
        />
      )}
    </button>
  );
}

export default Button;
