import { AxiosError } from "axios";

import { AxiosErrorResponse } from "~/utils/interfaces/common";

export const parseError = (err: any): { error: string } => {
  if ((err as AxiosError).isAxiosError) {
    const error = err as AxiosErrorResponse;
    if (error.response?.data?.err) {
      return { error: error.response.data.err };
    }
  }
  return { error: JSON.stringify(err) };
};
