import React from "react";

import clsx from "clsx";

function MagnifyingGlassIcon(props: React.ComponentPropsWithoutRef<"svg"> & { className?: string }) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="6.99994"
        cy="7"
        r="4.19415"
        transform="rotate(45 6.99994 7)"
        className={clsx("stroke-examedi-black-dark stroke-[1.51119px]", props.className)}
      />
      <path
        d="M10.1498 10.0344L12.8036 12.6882"
        className={clsx("stroke-examedi-black-dark stroke-[1.51119px]", props.className)}
        strokeLinecap="round"
      />
    </svg>
  );
}

export default MagnifyingGlassIcon;
